// set basepath of project for webpack
__webpack_public_path__ = document.querySelector('html').dataset.basepath || '/assets/frontend/js/';
import { sentryConfig } from './core/config';

import 'console-polyfill';
import './polyfills/SVGElement.classList';
import './polyfills/SVGElement.dataset';
import elementClosestPolyfill from 'element-closest'
elementClosestPolyfill(window)
import 'custom-event-polyfill'

import * as Sentry from '@sentry/browser';

if (sentryConfig.dsn) {
    Sentry.init(sentryConfig)
    Sentry.configureScope(scope => {
        scope.setTag('logger', 'js')
        scope.setTag('module', 'frontend')
    })
}

try {
    Object.values({}) // polyfill pro facebook
} catch (e) {}

import { queryAll } from './utils/dom'

import './plugins/nette.ajax';
import './plugins/nette.ajax.history';
import './plugins/giant.autosubmit';
import './plugins/giant.show-if';

// nette
import './nette/NetteValidationMessages';
import './nette/NetteSnippets';
import './nette/NetteEvents';
import './nette/NetteLoading';

import Nette from './nette/NetteForms';
window.Nette = Nette;
Nette.initOnLoad();

if ($.nette) {
    $.nette.init();
}

import TouchMouseInputResolver from './meta/TouchMouseInputResolver';
new TouchMouseInputResolver();

/* ---------------------------------- */

import { loadComponents } from './core/componentUtils'
loadComponents()

// import Swup from 'swup';
// import swupGtmPlugin from 'swup/plugins/swupGtmPlugin';
// import detectie from 'detectie';

/**
 * Swup stuff
 */

// if (!detectie()) {
//     const swup = new Swup({
//         LINK_SELECTOR: 'a[href^="' + window.location.origin + '"]:not([data-no-swup]):not([g-component]), a[href^="/"]:not([data-no-swup]):not([g-component]), a[href^="#"]:not([data-no-swup]):not([g-component])',
//         elements: ['#swup', '#mobile-navigation', '#sidebar-recommended', '#sidebar-content', '#header-categories', '#header-container'],
//         debugMode: false,
//         //doScrollingRightAway: true,
//         animateScroll: true,
//         preload: false,
//         cache: true,
//         //plugins: [swupGtmPlugin]
//     });

//     // components
//     swup.on('willReplaceContent', event => {
//         document.querySelectorAll('[data-swup]').forEach(element => {
//             removeComponents(element)
//         });
//     });

//     swup.on('contentReplaced', event => {
//         document.querySelectorAll('[data-swup]').forEach(element => {
//             loadComponents(element)
//         });
//     });

//     // scroll
//     swup.on('willReplaceContent', event => {
//         swup.options.animateScroll = false;
//     });

//     swup.on('contentReplaced', event => {
//         Nette.reinit();
//         setTimeout(() => {
//             swup.options.animateScroll = true;
//         });
//     });
// } else {
//     // fix for IE not scrolling to anchor elements in overflow: hidden elements
//     queryAll('[href^="#"]').forEach(link => {
//         link.addEventListener('click', event => {
//             let $header = document.getElementById('main-header')
//             document.documentElement.scrollTop = Math.round(document.querySelector(event.target.hash).getBoundingClientRect().top) + window.pageYOffset - $header.getBoundingClientRect().height;
//         });
//     });
// }

/**
 * lazy loading
 */
window.lazySizesConfig.lazyClass = 'is-lazy';
window.lazySizesConfig.loadingClass = 'is-lazy-loading';
window.lazySizesConfig.loadedClass = 'is-lazy-loaded';
import 'lazysizes';
