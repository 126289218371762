var timer = null;

export function submitForm(node) {
    const form = node.closest('form')

    if (form.classList.contains('ajax')) {
        $.nette.ajax({
            url: form.action,
            method: form.method || 'GET',
            dataType: 'json',
            data: $(form).serialize()
        });
    } else {
        form.submit()
    }
}

document.addEventListener('change', event => {
    const form = event.target.closest('form')
    const field = event.target.closest('select[data-autosubmit], input[data-autosubmit]')

    if (field || form?.dataset?.autosubmit != null) {
        submitForm(form)
    }
})

document.addEventListener('keyup', event => {
    const form = event.target.closest('form')
    const field = event.target.closest('input[data-autosubmit], textarea[data-autosubmit]')

    if (field || form?.dataset?.autosubmit != null) {
        clearTimeout(timer)
        timer = setTimeout(() => submitForm(form), 200)
    }
})